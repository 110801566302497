// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Onenoteltiintegra1on2 from "../../blocks/onenoteltiintegra1on2/src/Onenoteltiintegra1on2";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Panopto2 from "../../blocks/panopto2/src/Panopto2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Equella2 from "../../blocks/equella2/src/Equella2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Tabletsupport64 from "../../blocks/tabletsupport64/src/Tabletsupport64";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Googledriveintegration3 from "../../blocks/googledriveintegration3/src/Googledriveintegration3";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Mathjax2 from "../../blocks/mathjax2/src/Mathjax2";
import Kaltura2 from "../../blocks/kaltura2/src/Kaltura2";
import Imageeditorintegration2 from "../../blocks/imageeditorintegration2/src/Imageeditorintegration2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Trash from "../../blocks/trash/src/Trash";
import Facetagging2 from "../../blocks/facetagging2/src/Facetagging2";
import Cctvintegration2 from "../../blocks/cctvintegration2/src/Cctvintegration2";
import Turni1n4 from "../../blocks/turni1n4/src/Turni1n4";
import Vrintegration2 from "../../blocks/vrintegration2/src/Vrintegration2";
import Mailchimp2 from "../../blocks/mailchimp2/src/Mailchimp2";
import Gsuitesso2 from "../../blocks/gsuitesso2/src/Gsuitesso2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Onedriveandonedriveforbusiness2 from "../../blocks/onedriveandonedriveforbusiness2/src/Onedriveandonedriveforbusiness2";
import Htmleditorintegration2 from "../../blocks/htmleditorintegration2/src/Htmleditorintegration2";
import Office365samlauth0andcustomsinglesignon2 from "../../blocks/office365samlauth0andcustomsinglesignon2/src/Office365samlauth0andcustomsinglesignon2";
import Msimmersivereader2 from "../../blocks/msimmersivereader2/src/Msimmersivereader2";
import Equationeditorintegration2 from "../../blocks/equationeditorintegration2/src/Equationeditorintegration2";
import Integrationwitharsdk2 from "../../blocks/integrationwitharsdk2/src/Integrationwitharsdk2";
import FacialTracking from "../../blocks/facialtracking/src/FacialTracking";
import SpellCheck from "../../blocks/spellcheck/src/SpellCheck";


const routeMap = {
Onenoteltiintegra1on2:{
 component:Onenoteltiintegra1on2,
path:"/Onenoteltiintegra1on2"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Panopto2:{
 component:Panopto2,
path:"/Panopto2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Equella2:{
 component:Equella2,
path:"/Equella2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Tabletsupport64:{
 component:Tabletsupport64,
path:"/Tabletsupport64"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Googledriveintegration3:{
 component:Googledriveintegration3,
path:"/Googledriveintegration3"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Mathjax2:{
 component:Mathjax2,
path:"/Mathjax2"},
Kaltura2:{
 component:Kaltura2,
path:"/Kaltura2"},
Imageeditorintegration2:{
 component:Imageeditorintegration2,
path:"/Imageeditorintegration2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Trash:{
 component:Trash,
path:"/Trash"},
Facetagging2:{
 component:Facetagging2,
path:"/Facetagging2"},
Cctvintegration2:{
 component:Cctvintegration2,
path:"/Cctvintegration2"},
Turni1n4:{
 component:Turni1n4,
path:"/Turni1n4"},
Vrintegration2:{
 component:Vrintegration2,
path:"/Vrintegration2"},
Mailchimp2:{
 component:Mailchimp2,
path:"/Mailchimp2"},
Gsuitesso2:{
 component:Gsuitesso2,
path:"/Gsuitesso2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Onedriveandonedriveforbusiness2:{
 component:Onedriveandonedriveforbusiness2,
path:"/Onedriveandonedriveforbusiness2"},
Htmleditorintegration2:{
 component:Htmleditorintegration2,
path:"/Htmleditorintegration2"},
Office365samlauth0andcustomsinglesignon2:{
 component:Office365samlauth0andcustomsinglesignon2,
path:"/Office365samlauth0andcustomsinglesignon2"},
Msimmersivereader2:{
 component:Msimmersivereader2,
path:"/Msimmersivereader2"},
Equationeditorintegration2:{
 component:Equationeditorintegration2,
path:"/Equationeditorintegration2"},
Integrationwitharsdk2:{
 component:Integrationwitharsdk2,
path:"/Integrationwitharsdk2"},
FacialTracking:{
 component:FacialTracking,
path:"/FacialTracking"},
SpellCheck:{
 component:SpellCheck,
path:"/SpellCheck"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;